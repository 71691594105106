const numeral = require("numeral");
const moment = require("moment");
import store from "@/store";
import qs from "qs";
export const formatNumber = (x) => {
  return numeral(x).format("0,0[.]0");
};

export const url = "https://api.restmenu.app";
// export const url = "http://localhost:1337";
export const isInTime = (from, to) => {
  let startTime = new Date("1900-01-01 " + from);
  let endTime = new Date("1900-01-01 " + to);
  let nowTime = new Date("1900-01-01 " + moment().format('HH:mm'));
  let isValid = nowTime >= startTime && nowTime <= endTime;
  return isValid;
};
export const to12Fromat = (time) => {
  if (time == null) return null;
  
  let hours = time.split(":")[0];
  let minutes = time.split(":")[1];
  let ampm = hours >= 12 ? 'PM' : 'AM';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? '0' + minutes : minutes;
  let strTime = hours + ':' + minutes + ' ' + ampm;
  return strTime.replace('000', '00');
};
export const formatDate = (date, showTime = true) => {
  showTime;
  var formate = "YYYY/MM/DD";
  if (showTime) {
    formate = "YYYY/MM/DD hh:mmA";
  }
  var ret = moment(date).format(formate);
  return ret;
};
export const hideFrom = (roles) => {
  var role = store.state.roles[store.getters.role];
  var permissions = roles(role);
  return permissions.indexOf(false) > -1;
};
export const float = (x) => {
  return typeof x == "string" ? parseFloat(x.replace(",", "")) : x;
};
export const afterDiscount = (product) => {
  if (parseInt(product.discount) > 0) {
    if (product.discount_type == 'percentage') {
      return (product.discount / 100) * product.price
    } else {
      return (product.price - parseInt(product.discount))
    }
  } else {
    return product.price
  }
}
export const uploadFile = (file) => {
  return new Promise((resolve, reject) => {
    var formData = new FormData();
    formData.append("files", file);
    fetch(url + "/api/upload", {
      method: "POST",
      body: formData,
    })
      .then((response) => response.json())
      .then((responseJson) => {
        resolve(responseJson[0]);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
export const qsParams = (params) => {
  return qs.stringify(params);
};
export const getStyle = (desgin) => {
  return {
    name: {
      color: desgin.nameColor,
      fontSize: desgin.nameSize + "px",
    },
    description: {
      color: desgin.descriptionColor,
      fontSize: desgin.descriptionSize + "px",
    },
    content: {
      color: desgin.contentColor,
      fontSize: desgin.contentSize + "px",
    },
  };
};
export default {
  formatNumber,
  formatDate,
  hideFrom,
  url,
  float,
  uploadFile,
  qsParams,
  isInTime,
  to12Fromat,
  getStyle,
  afterDiscount
};
