import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [
  {
    path: "/",
    name: "dashboard-home",
    component: () => import("@/views/dashoard/Home.vue"),
    meta: {
      title: "الصفحة الرئيسية",
    },
  },
  {
    path: "/done",
    name: "dashboard-done",
    component: () => import("@/views/dashoard/Done.vue"),
    meta: {
      title: "الصفحة الرئيسية",
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});
export default router;
